export const writeToLocalStorage = (key, value) => {
  const store = JSON.parse(localStorage.getItem('intellidex') || '{}');
  localStorage.setItem(
    'intellidex',
    JSON.stringify({
      ...store,
      [key]: value,
    }),
  );
};

export const getFromLocalStorage = (key) => {
  const store = JSON.parse(localStorage.getItem('intellidex') || '{}');
  return store[key];
};

export function transformQuarterString(input) {
  const match = input.match(/^(\d{4})(Q[1-4])$/);
  if (!match) {
    return `${input.slice(5, 7)}/${input.slice(2, 4)}`;
  }

  const year = match[1];
  const quarter = match[2];

  return `${quarter}/${year}`;
}

export const clearLocalStorage = () => {
  localStorage.setItem(
    'intellidex',
    JSON.stringify({ accessToken: null, refreshToken: null, userRole: null, userName: null }),
  );
};

export const getFormatedData = (data, format = 'YYYY-MM') => {
  const date = data.format(format);
  return date;
};

export const getValuesFromKeys = (keys) =>
  keys.map((key) => {
    const value = key.split('-');
    return { column: value[0], row: value[1], table: value[2], infoRow: value[3] };
  });

export function flatten(list = []) {
  const result = [];
  list.forEach((item) => {
    result.push(item);
    if (item.children) {
      result.push(...flatten(item.children));
    }
  });
  return result;
}

export const getSelectedPairs = (treeData, values, columns) =>
  values.reduce((accum, item) => {
    let column;
    treeData.forEach((table) => {
      if (table.key === Number(item.table) && table) {
        column = table?.children
          .filter((row) => row.key.split('t')[0] === item.row)[0]
          .children.filter(({ key }) => key.split('-')[0] === item.column)[0];
      }
    });
    if (accum[`${item.table}-${item.row}`]) {
      return {
        ...accum,
        [`${item.table}-${item.row}`]: [
          ...accum[`${item.table}-${item.row}`],
          `${columns[item.column - 1]?.columnnumber} - ${column?.title}`,
        ],
      };
    }
    return {
      ...accum,
      [`${item.table}-${item.row}`]: [
        `${columns[item.column - 1]?.columnnumber} - ${column?.title}`,
      ],
    };
  }, {});
