import styled from 'styled-components';
import { Button, DatePicker, Select, Table, Modal, Switch } from 'antd';

const { RangePicker } = DatePicker;

export const SideBarWrapper = styled('div')`
  position: sticky;
  top: 120px;
  left: 0;
  margin: 0;
  padding: 20px 15px;
  background: #fff;
  width: 330px;
  min-height: calc(100vh - 131px);
`;

export const SideBarInputsWrapper = styled('div')`
  position: relative;
  width: 100%;
`;

export const SideBarSelect = styled(Select)`
  width: 100%;
  max-width: 320px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
`;

export const SideBarDate = styled(RangePicker)`
  width: 100%;
  max-width: 320px;
  min-height: 42px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
`;

export const SwitchText = styled('p')`
  color: #bfbdbd;
  text-transform: uppercase;
  margin: 0px;
  flex: 1;
  text-align: left;
`;

export const SwitchOuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Increased gap between elements */
  margin: 20px 0;
  flex-direction: column;
`;

export const SwitchRow = styled.div`
  display: flex;
  align-items: center; /* Aligns text and switch vertically */
  justify-content: space-between; /* Proper spacing between text and switch */
  gap: 15px; /* Additional spacing between text and switch */
  width: 100%; /* Ensure alignment within the container */
  max-width: 400px; /* Optional: Set a max-width for consistency */
`;

export const SideBarInputHeading = styled('p')`
  position: relative;
  left: 7px;
  top: 23px;
  padding: 0 5px;
  width: 140px;
  color: #bfbdbd;
  z-index: 100;
  background: white;
  text-transform: uppercase;
`;

export const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #f0542b;
  }
`;

export const SideBarControlsWrapper = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0;
  margin-top: 5px;
`;

export const GenerateButton = styled(Button)`
  width: 188px;
  height: 40px;
  background: #f0542b;
  margin-left: 10px;
  border-radius: 3px;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  color: #fff;
`;

export const ResetButton = styled(Button)`
  width: 120px;
  height: 40px;
  border-radius: 3px;
  background: #849fb6;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  color: #fff;
  &:hover {
    color: #849fb6;
    background: #fff;
    border-color: #849fb6;
  }
`;

export const AddNewDataButton = styled(Button)`
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid black;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 5px 0;
`;

export const ShowSelectedDataWrapper = styled('div')`
  width: 100%;
  min-height: 240px;
  margin-top: 20px;
  background: #f4f4f4;
  border-radius: 3px;
  flex: none;
  order: 2;
  flex-grow: 0;
`;

export const ShowSelectedDataHeading = styled('div')`
  text-align: left;
  font-weight: bold;
  color: black;
  background: #e9e9e9;
`;

export const ShowSelectedDataText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  padding: 10px;
  display: block;
  text-align: left;
  background: #f4f4f4;
`;

export const ShowSelectedDataTable = styled(Table)`
  background: #f4f4f4;
`;

export const SideBarOperationsWrapper = styled('div')`
  width: 100%;
  padding: 0;
  position: relative;
`;

export const SideBarOperationsInput = styled('div')`
  width: 100%;
  max-width: 320px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  height: 34px;
  cursor: pointer;
`;

export const SideBarOperationsControl = styled('p')`
  position: absolute;
  top: 33px;
  right: 30px;
  text-align: right;
  padding: 0;
  margin: 0;
  z-index: 100;
  font-weight: 900;
  font-size: 18px;
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

export const OperationsMenu = styled(Modal)`
  top: 30px;
  z-index: 1065;
  & .anticon-close {
    position: relative;
    top: -15px;
    right: -13px;
  }
  & .ant-modal-body {
    z-index: 1060;
  }
`;

export const OperationsControlsWrapper = styled('div')`
  position: relative;
  margin: 0 auto;
  padding: 10px;
  width: 338px;
`;

export const AddTablesButton = styled(Button)`
  border-radius: 3px;
  width: 188px;
  height: 40px;
  background: #f0542b;
  color: #fff;
  margin-left: 10px;
`;

export const ResetTablesButton = styled(Button)`
  width: 120px;
  height: 40px;
  border-radius: 3px;
  background: #666666;
  color: #fff;
  &:hover {
    color: #666666;
    background: #fff;
    border-color: #666666;
  }
`;

export default SideBarWrapper;
