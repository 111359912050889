import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Styled from './SideBar.styles';
import * as actions from './actions';
import SIDE_BAR, {
  DATABASES_AVAILABLE_DATES_FROM,
  PICKER_TYPE,
  CONSUMER_CREDIT_ID,
  BANKS_SELECT_DISABLED,
} from './constants';
import { selectors } from './reducer';
import OperationsWindow from '../OperationsWindow';
import { getFormatedData, getValuesFromKeys } from '../../global/helpers';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

const DataSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const now = new Date();

  const operationsMenuOpen = useSelector(selectors.operationsMenuOpen);

  const addBank = (option) => dispatch(actions.setSelectedBanks(option));
  const removeBank = (option) => dispatch(actions.unsetSelectedBanks(option));
  const selectColumns = (keys) => dispatch(actions.updateKeys(keys));
  const setDatabase = (db) => dispatch(actions.updateDataBase(db));
  const getDataField = (db) => dispatch(actions.getData.request(db));
  const getOperations = (db) => dispatch(actions.getOperations.request(db));
  const getBanks = (db) => dispatch(actions.getBanks.request(db));
  const setPeriod = (period) => dispatch(actions.updatePeriod(period));
  const clearData = () => {
    dispatch(actions.clearSelectedData());
    history.push('/');
  };
  const unsetDatapoints = (key) => {
    dispatch(actions.unsetDataPoints(key));
    dispatch(actions.openDatapoints(false));
  };
  const setDatapoints = (key) => {
    dispatch(actions.updateDataPoints(key));
    dispatch(actions.openDatapoints(false));
  };

  useEffect(() => {
    dispatch(actions.getDatabase.request());
    getBanks();
    dispatch(actions.getColumns.request());
  }, [dispatch]);

  const { Option } = Select;
  const [inflationCoefficientChecked, setInflationCoefficientChecked] = useState(false);
  const [inflationByYearChecked, setInflationByYearChecked] = useState(false);
  const banks = useSelector(selectors.banks);
  const databases = useSelector(selectors.databases);
  const selectedBanks = useSelector(selectors.selectedBanks);
  const selectedDatabases = useSelector(selectors.selectedDatabases);
  const selectedDatapoints = useSelector(selectors.selectedDatapoints);
  const triggers = useSelector(selectors.triggers);
  const targetKeys = useSelector(selectors.targetKeys);
  const period = useSelector(selectors.period);
  const operations = useSelector(selectors.operations);
  const dataForSelect = useSelector(selectors.dataForSelect);
  const datapointsOpen = useSelector(selectors.datapointsOpen);
  const bankSelectDisabled = selectedDatabases.some((db) => BANKS_SELECT_DISABLED?.includes(db));
  const isMarkedTotalSelected =
    selectedDatapoints &&
    !selectedDatabases.includes(CONSUMER_CREDIT_ID) &&
    'TARGET_TABLE' in selectedDatapoints &&
    selectedDatapoints.TARGET_TABLE === true;

  const openDatapoints = () => {
    if (!datapointsOpen) {
      dispatch(actions.openDatapoints(true));
    }
  };
  const [isDate, setIsDate] = useState(false);

  useEffect(() => {
    if (databases?.length > 0) {
      const defaultDatabase = databases[0]?.bdid;
      setDatabase([defaultDatabase]);
      getDataField(defaultDatabase);
      getOperations(defaultDatabase);
    }
  }, [databases]);

  useEffect(() => {
    if (!period)
      dispatch(
        actions.updatePeriod([
          null,
          moment(`${now.getFullYear()}/${now.getMonth() + 1}`, 'YYYY/MM'),
        ]),
      );
    // eslint-disable-next-line
  }, [period, dispatch]);

  const getDbKeys = (bds) =>
    databases
      .filter((database) => bds.includes(database.bdid))
      .map((database) => database.key)
      .join(',');

  const treeData = useMemo(() => {
    switch (dataForSelect.type) {
      case 'tables':
        return [...(dataForSelect?.data || [])]
          .sort((a, b) => a.tablenumber - b.tablenumber)
          .map((table) => ({
            title: `Table ${table.tablenumber}: ${table.itemdescription}`,
            key: table.tablenumber,
            checkable: false,
            children: table.data.rows.map((item) => ({
              title: `Row ${item.rownumber}: ${item.itemdescription}`,
              key: `${item.rownumber}t${table.tablenumber}`,
              checkable: false,
              children: table.data.columns.map((col) => ({
                title: col.columndescription,
                key: `${col.columnnumber}-${item.rownumber}-${table.tablenumber}`,
              })),
            })),
          }));
      case 'rows':
        return [...(dataForSelect?.data || [])].map((table) => ({
          title: table.itemdescription,
          key: table.itemdescription,
          checkable: false,
          children: table.data.map((row) => ({
            title: row.itemdescription,
            key: `1-1-${row.rownumber}`,
          })),
        }));
      default:
        return dataForSelect?.data
          ? Object.keys(dataForSelect.data).map((table) => ({
              title: table,
              key: table,
              checkable: false,
              children: Object.keys(dataForSelect.data[table]).map((item) => ({
                title: item,
                key: `${table}t${item}`,
                checkable: false,
                children: Object.keys(dataForSelect.data[table][item]).map((row) => ({
                  title: row,
                  key: `${table}-${item}-${row}`,
                  checkable: false,
                  children: Object.values(dataForSelect.data[table][item][row]).map((innerRow) => ({
                    key: `${table}-${item}-${row}-${innerRow}`,
                    title: innerRow,
                  })),
                })),
              })),
            }))
          : [];
    }
  }, [dataForSelect]);

  useEffect(() => {
    if (treeData || treeData?.length > 0) {
      dispatch(actions.setTreeData(treeData));
    }
  }, [dispatch, treeData]);

  const getChartsHeaders = () => {
    if (period && selectedBanks?.length > 0 && targetKeys?.length > 0) {
      const startDate = period[0];
      const endDate = period[1];
      const selectedTables = [];
      const selectedRows = [];
      const values = getValuesFromKeys(targetKeys);
      const getTriggers = () => {
        if (selectedDatapoints) {
          return {
            ...SIDE_BAR.intialTrigger,
            ...selectedDatapoints,
            MARKET_TOTAL_WITH_CRISIS_INDEX: inflationCoefficientChecked,
            MARKET_TOTAL_WITH_CRISIS_INDEX_BY_YEAR: inflationByYearChecked,
          };
        }
        return {
          TARGET_TABLE: true,
          MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: true,
          PERCENT_OF_ASSETS_REF_TABLE: true,
          MARKET_SHARE_REF: true,
        };
      };
      values?.forEach(({ table, row }) => {
        if (!selectedTables.includes(table)) {
          selectedTables.push(table);
        }
        if (!selectedRows.includes(row)) selectedRows.push(row);
      });
      const db = getDbKeys(selectedDatabases);
      const data = {
        db,
        banks_id_list: selectedBanks
          ?.map((item) => banks?.find(({ name }) => name === item))
          ?.map(({ id }) => id)
          .flat(),
        period: [getFormatedData(startDate), getFormatedData(endDate)],
        tables:
          dataForSelect.type === 'consumer_credit'
            ? targetKeys.map((item) => {
                const parts = item?.split(/-(?=[A-Z])/);
                const [sheetNumber, blockType, totalSumValue] = parts
                  .slice(0, 3)
                  .map((part) => part.trim());
                let agreement = '';
                let totalSum = totalSumValue;
                if (totalSumValue.includes('-')) {
                  const splittedValues = totalSumValue.split('-');
                  const total = splittedValues[0];
                  const agreementNew = splittedValues.slice(1).join('-');
                  agreement = agreementNew;
                  totalSum = total;
                } else {
                  agreement = parts.slice(3).join('-');
                }
                return {
                  sheet_number: sheetNumber ? sheetNumber.split(':')[0].trim() : null,
                  block_type: blockType || null,
                  total_sum_value: totalSum || totalSumValue || null,
                  agreement: agreement || null,
                };
              })
            : selectedTables.map((id) => {
                switch (dataForSelect.type) {
                  case 'rows':
                    return {
                      table_id: '1',
                      rows: selectedRows.map(() => ({
                        id: String(id),
                        columns: ['1'],
                      })),
                    };
                  default:
                    return {
                      table_id: id,
                      rows: selectedRows.map((rowId) => {
                        const filteredValues = values?.filter(
                          ({ row, table }) => table === id && rowId === row,
                        );
                        return {
                          id: rowId,
                          columns: filteredValues ? filteredValues.map(({ column }) => column) : [],
                        };
                      }),
                    };
                }
              }),
        trigger: getTriggers(),
      };
      dispatch(actions.getCharts.request(data));
      dispatch(actions.updateInternalData(dataForSelect));
      dispatch(actions.setInternalTreeData(treeData));
      dispatch(actions.setInternalTargetKeys(targetKeys));
    } else {
      const requierdFields = [
        { key: 'date', value: period },
        { key: 'banks', value: selectedBanks },
        { key: 'tables', value: targetKeys },
      ];
      requierdFields.forEach(({ key, value }) => {
        if (!value || value.length === 0) {
          openNotification({
            type: NOTIFICATIONS_CONFIG.types.warning,
            message: `Please select some ${key}`,
          });
        }
      });
    }
  };

  const clearDataOnDbSelect = () => {
    dispatch(actions.updateKeys([]));
    dispatch(actions.resetDataPoints());
    dispatch(actions.resetSelectedBanks());
    dispatch(actions.resetOperations());
  };

  const disabledDate = (current) =>
    // eslint-disable-next-line
    selectedDatabases.some((db) => DATABASES_AVAILABLE_DATES_FROM?.hasOwnProperty(db)) &&
    current &&
    current <
      moment(
        Math.min(
          ...selectedDatabases
            // eslint-disable-next-line
            .filter((db) => DATABASES_AVAILABLE_DATES_FROM?.hasOwnProperty(db))
            .map((db) => moment(DATABASES_AVAILABLE_DATES_FROM[db]).valueOf()),
        ),
      );

  const onDataBaseSelect = (db) => {
    if (db === CONSUMER_CREDIT_ID || selectedDatabases.includes(CONSUMER_CREDIT_ID)) {
      setDatabase([db]);
      const keys = getDbKeys([db]);
      getDataField(keys);
      getBanks(keys);
      getOperations(keys);
    } else {
      setDatabase([...selectedDatabases, db]);
      const keys = getDbKeys([...selectedDatabases, db]);
      getDataField(keys);
      getBanks(keys);
      getOperations(keys);
    }
    clearDataOnDbSelect();
  };

  const onDataBaseDeselect = (db) => {
    const filteredDatabases = selectedDatabases.filter((key) => db !== key);
    const keys = getDbKeys(filteredDatabases);
    if (filteredDatabases.length) {
      clearDataOnDbSelect();
      getBanks(keys);
      getOperations(keys);
      getDataField(keys);
    }
    setDatabase(filteredDatabases);
  };

  return (
    <Styled.SideBarWrapper
      onClick={() => {
        if (!isDate) dispatch(actions.openDatapoints(false));
      }}
    >
      <Styled.SideBarInputsWrapper>
        <Styled.SideBarInputHeading>{SIDE_BAR.databaseHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onSelect={onDataBaseSelect}
          onDeselect={onDataBaseDeselect}
          value={selectedDatabases}
        >
          {databases?.map((item) => (
            <Option key={item.bdid} value={item.bdid}>
              {item.bdid}
            </Option>
          ))}
        </Styled.SideBarSelect>
        {bankSelectDisabled ? null : (
          <>
            <Styled.SideBarInputHeading>{SIDE_BAR.banksHeadingText}</Styled.SideBarInputHeading>
            <Styled.SideBarSelect
              mode="multiple"
              maxTagCount="responsive"
              onSelect={addBank}
              onDeselect={removeBank}
              value={selectedBanks}
            >
              {banks &&
                [...banks]
                  .sort((a, b) => {
                    if (SIDE_BAR.defaultBanks.includes(a.name.toLowerCase())) {
                      return 1;
                    }
                    if (SIDE_BAR.defaultBanks.includes(b.name.toLowerCase())) {
                      return -1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item) => (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
            </Styled.SideBarSelect>
          </>
        )}
        <Styled.SideBarInputHeading>{SIDE_BAR.periodHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarDate
          disabledDate={disabledDate}
          onChange={setPeriod}
          picker={PICKER_TYPE[selectedDatabases[0]] ?? 'date'}
          value={period}
          format="YYYY/MM"
        />
        <Styled.SideBarOperationsWrapper>
          <Styled.SideBarInputHeading>{SIDE_BAR.operationsHeadingText}</Styled.SideBarInputHeading>
          <Styled.SideBarOperationsInput
            onClick={() => {
              dispatch(actions.openOperationsMenu(true));
            }}
          />
        </Styled.SideBarOperationsWrapper>
        <Styled.SideBarInputHeading>{SIDE_BAR.datapointsHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onSelect={setDatapoints}
          onDeselect={unsetDatapoints}
          value={triggers}
          open={datapointsOpen}
          onClick={openDatapoints}
          onMouseLeave={() => setIsDate(false)}
          onMouseEnter={() => setIsDate(true)}
        >
          {operations
            .filter((operation) => operation.key !== 'MARKET_TOTAL')
            .map((operation) => (
              <Option
                value={operation.key}
                key={operation.key}
                onMouseLeave={() => setIsDate(false)}
                onMouseEnter={() => setIsDate(true)}
              >
                {operation.title}
              </Option>
            ))}
        </Styled.SideBarSelect>
        {isMarkedTotalSelected ? (
          <Styled.SwitchOuterContainer>
            <Styled.SwitchRow>
              <Styled.SwitchText>Enable inflation coefficient</Styled.SwitchText>
              <Styled.StyledSwitch
                checked={inflationCoefficientChecked}
                onChange={setInflationCoefficientChecked}
              />
            </Styled.SwitchRow>
            <Styled.SwitchRow>
              <Styled.SwitchText>Enable inflation coefficient by years</Styled.SwitchText>
              <Styled.StyledSwitch
                checked={inflationByYearChecked}
                onChange={setInflationByYearChecked}
              />
            </Styled.SwitchRow>
          </Styled.SwitchOuterContainer>
        ) : null}
      </Styled.SideBarInputsWrapper>
      <Styled.SideBarControlsWrapper>
        <Styled.ResetButton onClick={clearData}>
          <RedoOutlined />
          {SIDE_BAR.resetButtonText}
        </Styled.ResetButton>
        <Styled.GenerateButton onClick={() => getChartsHeaders()}>
          {SIDE_BAR.generateButtonText}
        </Styled.GenerateButton>
      </Styled.SideBarControlsWrapper>
      <Styled.ShowSelectedDataWrapper>
        <Styled.ShowSelectedDataHeading>
          {SIDE_BAR.banksSelectedText}
        </Styled.ShowSelectedDataHeading>
        <Styled.ShowSelectedDataText>
          {selectedBanks.length > 0 &&
            selectedBanks
              ?.slice()
              ?.sort((a, b) => a - b)
              ?.map((bank) => <p>{bank}</p>)}
        </Styled.ShowSelectedDataText>
        <Styled.ShowSelectedDataHeading>
          {SIDE_BAR.seriesSelectedText}
        </Styled.ShowSelectedDataHeading>
        <Styled.ShowSelectedDataTable
          dataSource={getValuesFromKeys(targetKeys)}
          columns={SIDE_BAR.tableColumns}
          pagination={false}
        />
      </Styled.ShowSelectedDataWrapper>
      {operationsMenuOpen && (
        <Styled.OperationsMenu
          width="calc(100%-100px)"
          heigh="800px"
          footer={null}
          onCancel={() => dispatch(actions.openOperationsMenu(false))}
          visible
        >
          <OperationsWindow
            onChange={selectColumns}
            targetKeys={targetKeys}
            dataSource={treeData}
          />
          <Styled.OperationsControlsWrapper>
            <Styled.ResetTablesButton onClick={() => selectColumns([])}>
              <RedoOutlined />
              {SIDE_BAR.operationsRefreshButtonText}
            </Styled.ResetTablesButton>
            <Styled.AddTablesButton onClick={() => dispatch(actions.openOperationsMenu(false))}>
              {SIDE_BAR.operationsAddButtonText}
            </Styled.AddTablesButton>
          </Styled.OperationsControlsWrapper>
        </Styled.OperationsMenu>
      )}
    </Styled.SideBarWrapper>
  );
};

export default DataSelect;
