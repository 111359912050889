import ApiService from '../../global/service/api';

export class SideBarService extends ApiService {
  getDatabases = async () => this.get({ url: 'databases/' });

  getBanks = async (db) => this.get({ url: 'banks/', params: { db } });

  getSelectData = async (db) => this.get({ url: 'data/', params: { db } });

  getCharts = async (data, db) => this.post({ url: 'charts/', body: data, params: { db } });

  getOperations = async (db) => this.get({ url: 'operations/', params: { db } });

  getColumns = async () => this.get({ url: `rowsColumnsByTable/1/` });
}

export default new SideBarService();
