import styled from 'styled-components';
import { Transfer, Tree } from 'antd';

export const TablesTransfer = styled(Transfer)`
  max-height: 770px;
  overflow-y: auto;

  .ant-transfer-list-body {
    overflow-y: auto !important; /* Enable vertical scrolling */
    max-height: 770px !important; /* Set a height limit */
  }
`;

export const TablesTree = styled(Tree)``;
