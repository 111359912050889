import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from '../../global/helpers';
import * as Styled from './OperationsWindow.styles';

const OperationsWindow = ({ targetKeys, onChange, dataSource }) => {
  const isChecked = (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1;

  const transferDataSource = flatten(dataSource);

  return (
    <Styled.TablesTransfer
      targetKeys={targetKeys}
      onChange={onChange}
      dataSource={transferDataSource}
      render={(item) => {
        const label = `${item.title}( ${item.key} )`;
        return label;
      }}
      showSelectAll={false}
    >
      {
        // eslint-disable-next-line
        ({ direction, onItemSelect, selectedKeys }) => {
          if (direction === 'left') {
            const checkedKeys = [...selectedKeys, ...targetKeys];
            return (
              <Styled.TablesTree
                treeData={dataSource}
                checkedKeys={checkedKeys}
                checkable="false"
                onCheck={(_, { node }) => {
                  onItemSelect(node.key, !isChecked(checkedKeys, node.key));
                }}
                virtual="true"
                height={770}
              />
            );
          }
        }
      }
    </Styled.TablesTransfer>
  );
};

OperationsWindow.propTypes = {
  // eslint-disable-next-line
  targetKeys: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line
  dataSource: PropTypes.array,
};

OperationsWindow.defaultProps = {
  targetKeys: null,
  dataSource: null,
};

export default OperationsWindow;
