import { call, put, all, takeLatest } from 'redux-saga/effects';
import SideBarService from './service';
import * as actions from './actions';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

export function* getDatabaseSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getDatabases, payload);
    yield put(actions.getDatabase.success(response.data));
  } catch (err) {
    yield put(actions.getDatabase.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Databases Data Load Failed',
      description: err.message,
    });
  }
}

export function* getBanksSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getBanks, payload);
    yield put(actions.getBanks.success(response.data));
    if (response.data.length === 1) {
      yield put(actions.setSelectedBanks(response.data[0].name));
    }
  } catch (err) {
    yield put(actions.getBanks.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Banks Data Load Failed',
      description: err.message,
    });
  }
}

export function* getDataSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getSelectData, payload);
    yield put(actions.getData.success(response.data));
  } catch (error) {
    yield put(actions.getData.error(error));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Data for Select Load Failed',
      description: error.message,
    });
  }
}

export function* getOperationsSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getOperations, payload);
    yield put(actions.getOperations.success(response.data.operations));
  } catch (error) {
    yield put(actions.getOperations.error(error));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Operations Load Failed',
      description: error.message,
    });
  }
}

export function* getChartsSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getCharts, payload, payload.db);
    yield put(actions.getCharts.success(response.data.result[0]));
  } catch (err) {
    yield put(actions.getCharts.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Charts data loading error',
      description: err.message,
    });
  }
}

export function* getColumnsSaga() {
  try {
    const response = yield call(SideBarService.getColumns);
    yield put(actions.getColumns.success(response.data[0].columns));
  } catch (err) {
    yield put(actions.getColumns.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Columns Loading Failed',
      description: err.message,
    });
  }
}

export function* sideBarSagaWatcher() {
  yield all([
    takeLatest(actions.getDatabase.REQUEST, getDatabaseSaga),
    takeLatest(actions.getData.REQUEST, getDataSaga),
    takeLatest(actions.getOperations.REQUEST, getOperationsSaga),
    takeLatest(actions.getBanks.REQUEST, getBanksSaga),
    takeLatest(actions.getColumns.REQUEST, getColumnsSaga),
    takeLatest(actions.getCharts.REQUEST, getChartsSaga),
  ]);
}

export default sideBarSagaWatcher;
