import React from 'react';

export const GET_BANKS = 'GET_BANKS';
export const GET_DATABASE = 'GET_DATABASE';
export const GET_ROWS_COLUMNS_BY_TABLES = 'GET_ROWS_COLUMNS_BY_TABLES';
export const GET_COLUMNS = 'GET_COLUMNS';
export const GET_TABLES = 'GET_TABLES';
export const GET_CHARTS = 'GET_CHARTS';
export const GET_DATA = 'GET_DATA';
export const GET_OPERATIONS = 'GET_OPERATIONS';
export const OPEN_OPERATIONS_MENU = 'OPEN_OPERATIONS_MENU';
export const OPEN_PRINT_DIALOG = 'OPEN_PRINT_DIALOG';
export const OPEN_DATAPOINTS = 'OPEN_DATAPOINTS';
export const SET_TREE_DATA = 'SET_TREE_DATA';
export const SET_INTERNAL_TREE_DATA = 'SET_INTERNAL_TREE_DATA';
export const SET_INTERNAL_TARGET_KEYS = 'SET_INTERNAL_TARGET_KEYS';
export const SET_SELECTED_BANKS = 'SET_SELECTED_BANKS';
export const UNSET_SELECTED_BANKS = 'UNSET_SELECTED_BANKS';
export const RESET_SELECTED_BANKS = 'RESET_SELECTED_BANKS';
export const GET_INTERNAL_DATA = 'GET_INTERNAL_DATA';
export const UPDATE_INTERNAL_DATA = 'UPDATE_INTERNAL_DATA';
export const CONSUMER_CREDIT_ID = 'Consumer credit';
export const RESET_OPERATIONS = 'RESET OPERATIONS';
export const PERCENT_OF_TOTAL_CREDIT_GRANTED = 'PERCENT_OF_TOTAL_CREDIT_GRANTED';
export const MARKET_TOTAL_BAR_CHART = 'MARKET_TOTAL_BAR_CHART';
export const UPDATE_KEYS = 'UPDATE_KEYS';
export const BA200 = 'BA200';
export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const UPDATE_DATABASE = 'UPDATE_DATABASE';
export const UNSET_DATABASE = 'UNSET_DATABASE';
export const UPDATE_DATAPOINTS = 'UPDATE_DATAPOINTS';
export const RESET_DATAPOINTS = 'RESET_DATAPOINTS';
export const UNSET_DATAPOINTS = 'UNSET_DATAPOINTS';
export const CLEAR_SELECTED_DATA = 'CLEAR_SELECTED_DATA';
export const PERCENT_OF_TOTAL_RISK = 'PERCENT_OF_TOTAL_RISK';
export const PERCENT_OF_TOTAL_GROSS_CREDIT = 'PERCENT_OF_TOTAL_GROSS_CREDIT';
export const DEFAULT_PLUGINS = {
  plugins: {
    legend: {
      labels: {
        font: {
          size: 11,
        },
      },
    },
  },
};
export const SIDE_BAR = {
  databaseHeadingText: 'select database',
  banksHeadingText: 'select banks',
  periodHeadingText: 'select period',
  datapointsHeadingText: 'select operations',
  resetButtonText: 'reset',
  generateButtonText: 'generate output',
  newDataButtonText: 'add new data series',
  banksSelectedText: 'Banks Selected',
  seriesSelectedText: 'Series Selected',
  operationsHeadingText: 'Select data',
  tableColumns: [
    {
      title: 'Table',
      dataIndex: 'table',
      key: 'table',
      render: (text) => <p style={{ padding: '0 5px', marginBottom: 0 }}>{text}</p>,
    },
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      render: (text) => <p style={{ padding: '0 5px', marginBottom: 0 }}>{text}.</p>,
    },
    {
      title: 'Column',
      dataIndex: 'column',
      key: 'column',
      render: (text) => <p style={{ padding: '0 5px', marginBottom: 0 }}>{text}</p>,
    },
  ],
  operationsRefreshButtonText: 'Reset',
  operationsAddButtonText: 'ADD',
  points: {
    TARGET_TABLE: 'Nominal total amount (Rbn)',
    MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: 'Market total and as % of total assets',
    PERCENT_OF_ASSETS_REF_TABLE: 'Total as % of assets',
    MARKET_TOTAL_BAR_CHART: 'Market total bar chart',
    MARKET_SHARE_REF: 'By market share %',
    PERCENT_OF_TOTAL_GROSS_CREDIT: 'As a % of total gross credit exposure',
    PERCENT_OF_TOTAL_RISK: 'As a % of total risk weighted exposure',
    PERCENT_OF_TOTAL_ASSETS: 'As a % of total assets',
    FOUR_QUARTER: '4-Quarter',
    YEAR_OVER_YEAR: 'Year-over-Year (YoY)',
    PERCENT_OF_TOTAL_CREDIT_GRANTED: 'As a % of the total credit granted',
    MARKET_TOTAL: 'Market Total',
    MARKET_TOTAL_WITH_CRISIS_INDEX: 'Market total with inflation coefficient',
  },
  intialTrigger: {
    TARGET_TABLE: false,
    MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: false,
    PERCENT_OF_ASSETS_REF_TABLE: false,
    MARKET_SHARE_REF: false,
  },
  defaultBanks: [
    'all banks',
    'all others then those selected',
    'big 4',
    'big 5',
    'big 6',
    'd-sibs',
    'foreign controlled banks',
    'foreign bank branches',
    'locally controlled banks',
  ],
};

export const BAR_CHART_ENABLED = [
  SIDE_BAR.points.MARKET_TOTAL_BAR_CHART,
  'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS',
  'MARKET_TOTAL_BAR_CHART',
];

export const DATABASES_AVAILABLE_DATES_FROM = { BA200: 'June 2013' };
export const BANKS_SELECT_DISABLED = [CONSUMER_CREDIT_ID];
export const PICKER_TYPE = { 'Consumer credit': 'quarter' };

export default SIDE_BAR;
