import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import * as Styled from './PrintDialog.styles';
import { selectors } from '../SideBar/reducer';
import { openPrintDialog } from '../SideBar/actions';
import logo from '../../global/images/logo.png';
import { SIDE_BAR } from '../SideBar/constants';
import { PRINT_DIALOG } from './constants';
import { getFromLocalStorage, getSelectedPairs, getValuesFromKeys } from '../../global/helpers';

const PrintDialog = ({ componentToPrint }) => {
  const printRef = useRef();
  const dispatch = useDispatch();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const now = new Date();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = `${now.getDate()} ${monthNames[now.getMonth()]} ${now.getFullYear()}`;

  const db = useSelector(selectors.selectedDatabases);
  const targetKeys = useSelector(selectors.internalKeys);
  const tables = useSelector(selectors.internalData);
  const columns = useSelector(selectors.columns);
  const open = useSelector(selectors.printDialogOpen);
  const treeData = useSelector(selectors.internalTreeData);

  const getTableData = () => {
    if (tables && columns) {
      const tableRows = Array.isArray(tables.data)
        ? tables.data.reduce(
            (accum, table) => [...accum, ...(table.data?.rows || table.rows || [])],
            [],
          )
        : Object.values(tables.data).reduce(
            (accum, table) => [...accum, ...(table.data?.rows || table.rows || [])],
            [],
          );

      const rows = tableRows.reduce(
        (accum, row) => ({
          ...accum,
          [row.rownumber]: `${row?.rownumber} - ${row?.itemdescription}`,
        }),
        {},
      );
      const values = getValuesFromKeys(targetKeys);
      const selectedPairs = getSelectedPairs(treeData, values, columns);
      const tableData = [];
      Object.entries(selectedPairs).forEach((pair) => {
        const pairTableAndRow = pair[0].split('-');
        tableData.push({
          column: pair[1].join(', '),
          row: rows[pairTableAndRow[1]],
          table: `${pairTableAndRow[0]}: ${tables[pairTableAndRow[0] - 1]?.itemdescription}`,
        });
      });
      return tableData;
    }
    return null;
  };

  return (
    <Styled.PrintDialogWrapper
      width="calc(100%-100px)"
      onOk={handlePrint}
      onCancel={() => dispatch(openPrintDialog(false))}
      visible={open}
      footer={null}
    >
      <Styled.PrintButton onClick={handlePrint}>{PRINT_DIALOG.printButtonText}</Styled.PrintButton>
      <Styled.PrintComponentWrapper ref={printRef} pageStyle={{ width: 877, height: 1240 }}>
        <Styled.PrintComponentHeadingWrapper>
          <img src={logo} alt="" />
          <div>
            <div>{PRINT_DIALOG.resultHeaderText}</div>
            <h1>{`Generated by ${getFromLocalStorage('userName')} on ${date}`}</h1>
          </div>
        </Styled.PrintComponentHeadingWrapper>
        <Styled.PrintComponentHeaderWrapper>
          <span>{PRINT_DIALOG.showing}</span>
          <p>{`${PRINT_DIALOG.dbHeader} ${db}`}</p>
          <Table dataSource={getTableData()} columns={SIDE_BAR.tableColumns} pagination={false} />
        </Styled.PrintComponentHeaderWrapper>
        <Styled.ChartsWrapper>{componentToPrint()}</Styled.ChartsWrapper>
        <Styled.FooterWrapper>
          <div className="company-title">{PRINT_DIALOG.companyTitle}</div>
          <div className="source">{PRINT_DIALOG.sourceText}</div>
          <div className="link">{PRINT_DIALOG.website}</div>
        </Styled.FooterWrapper>
      </Styled.PrintComponentWrapper>
    </Styled.PrintDialogWrapper>
  );
};

PrintDialog.propTypes = {
  componentToPrint: PropTypes.func,
};

PrintDialog.defaultProps = {
  componentToPrint: null,
};

export default PrintDialog;
