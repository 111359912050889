/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import withAuth from '../../hocs/withAuth';
import { selectors } from '../LoginPage/reducer';
import UsersPage from '../UsersPage';
import AddUserPage from '../UsersPage/components/AddUserPage';
import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import * as Styled from './MainPage.styles';
import DashboardPage from '../DashboardPage';
import BigChart from '../../components/Charts/BigChart';

const MainPage = () => {
  const userRole = useSelector(selectors.userRole);

  const location = useLocation();

  const [isUsersPage, setIsUserPage] = useState(location.pathname.includes('/users'));

  useEffect(() => {
    setIsUserPage(location.pathname.includes('/users'));
  }, [location.pathname]);

  const getRoutesByRole = () => {
    if (userRole === 1)
      return (
        <Switch>
          <Route exact path="/" component={DashboardPage} />
          <Route path="/charts/:id/:number" component={BigChart} />
          <Route exact path="/users/add" component={AddUserPage} />
          <Route exact path="/users" component={UsersPage} />
          <Route path="/users/edit/:id" component={AddUserPage} />
          <Redirect to="/" />
        </Switch>
      );

    return (
      <Switch>
        <Route exact path="/" component={DashboardPage} />
        <Route path="/charts/:id/:number" component={BigChart} />
        <Redirect to="/" />
      </Switch>
    );
  };

  return (
    <>
      <Header />
      <Styled.ContentWrapper isUsersPage={isUsersPage}>
        {!isUsersPage && (
          <>
            <SideBar />
          </>
        )}
        <div className="content__wrapper">{getRoutesByRole()}</div>
      </Styled.ContentWrapper>
    </>
  );
};

export default withAuth(MainPage);
